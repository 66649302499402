<template>   
    <div>
    <CircularProgress v-if="loader"/>
    <v-row no-gutters>
      <v-col >
        <v-btn @click="getOrdersByStatus('active')" class="mt-4 btnText" small color="#434242" block dark elevation="0">Active Orders</v-btn>
      </v-col>
      <v-col class="mr-4">
        <v-btn @click="getOrdersByStatus('transit')" class="mt-4 ml-5 btnText" color="#434242" block small dark elevation="0">In Transit Orders</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="getOrdersByStatus('reached')" class="mt-4 btnText" color="#434242" block small dark elevation="0">Ready to Deliver</v-btn>
      </v-col>
      <v-col class="mr-4">
        <v-btn @click="getOrdersByStatus('past')" class="mt-4 ml-5 btnText" color="#434242" block small dark elevation="0">Past Orders</v-btn>
      </v-col>
    </v-row>

    <div class="mt-16" v-if="!ordersAvailable" >
      <v-col cols="12">
          <v-card class="d-flex justify-center mr-2 mt-16" color="#373535" dark elevation="0">
              <v-list color="#373535">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-16" size="90"> $notAvailableIcon </v-icon>
              </span>
              <span class="d-flex justify-center mt-5 cardTitle">There are no orders available.</span>
              </v-list>
          </v-card>
      </v-col>
      </div>
        
    <v-row v-if="ordersAvailable">
      <v-col cols="8">
          <span class="catalog_title">{{title}}</span>
      </v-col>
      
    </v-row>
    <div v-if="ordersAvailable">
      <div :key="orderItem._id" v-for="orderItem in ordersList">
        <BasketItem :key="orderItem._id" :orderItem="orderItem" @update-status="updateOrderStatus" />
      </div>
    </div>
    </div>
</template>
<script>
import orderServices from '@/services/orderServices'
import BasketItem from './BasketItem.vue'
import CircularProgress from '../loaders/CircularProgress.vue'
  export default {
    name: 'BasketMain',
    components: {
       BasketItem,
       CircularProgress 
    },
    data () {
        return {
            loader: false,
            ordersList: '',
            ordersAvailable: false,
            title: ''
        }
    },
    methods: {
        async getOrdersByStatus (orderStatus) {
          this.pageTitle(orderStatus)
          this.loader = true
          const getAllActiveOrders = (await orderServices.getAllCustomerOrdersByStatus(orderStatus)).data;
          if (getAllActiveOrders.activeOrderList.length !=0) {
            this.ordersList = getAllActiveOrders.activeOrderList
            this.ordersAvailable = true
          } else {
            this.ordersList = ''
            this.ordersAvailable = false
          }
          this.loader = false
        // console.log(this.ordersList[0].productId.productName)
        },
        async pageTitle (orderStatus) {
          if (orderStatus == "active") {
            this.title = "Active Order"
          } else if (orderStatus == "transit") {
            this.title = "In Transit Order"
          } else if (orderStatus == "reached") {
            this.title = "Ready to Deliver"
          } else if (orderStatus == "past") {
            this.title = "Past Orders"
          }
        },
        async updateOrderStatus (updateOrder) {
            this.loader = true
            const orderInfo = {
                orderStatus: updateOrder.status
            }
            // making the api call to update the basket
            try {
            await orderServices.updateOrderStatus(updateOrder.orderId, orderInfo).then(result => {
                if (result.data.activeOrder.orderStatus == updateOrder.status) {
                    this.ordersList.splice(this.ordersList.findIndex(a => a.orderId === updateOrder.orderId) , 1)
                }
                if(this.ordersList.length !=0) {
                    this.lodaer = false
                } else {
                    this.ordersAvailable = false
                    this.lodaer = false
                }

            }
            )
            } catch (err) {
                console.log(err)
            }
            this.loader = false
      }
    },
    async mounted () {
        this.loader = true
        const getAllActiveOrders = (await orderServices.getAllCustomerOrdersByStatus('active')).data;
        if (getAllActiveOrders.activeOrderList.length !=0) {
          this.ordersList = getAllActiveOrders.activeOrderList
          this.ordersAvailable = true
        } else {
          this.ordersList = ''
          this.ordersAvailable = false
        }
        this.loader = false
        // console.log(this.ordersList[0].productId.productName)
    }
  }
</script>
<style scoped>
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.catalog_title {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 46px;
color: rgba(255, 255, 255, 0.8);
}
.notAvailableText {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
color: #9B9A9A;
}
.btnText {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
color: rgba(255, 255, 255, 0.5);
}
</style>