<template>
    <div>

    <v-card class="mt-2" dark max-width="400" elevation="0">
        <v-list color="#434242">
            <v-list-item>

                <v-icon size="30" class="mr-3"> $boxActiveIcon </v-icon>

                <v-list-item-content>
                    <v-list-item-title class="customerName">{{orderItem.customerName}}</v-list-item-title>
                    <span class="mt-1 capitaliseText"><v-chip small color="#4B9C6D">{{orderItem.orderStatus}}</v-chip></span>
                </v-list-item-content>

                <v-list-item-content class="text-right">
                    <span class="houseText">House</span>
                    <span class="houseNo">{{orderItem.customerHouseNo}}</span>
                </v-list-item-content>

            </v-list-item>
            <v-divider class="ml-5 mr-5 dividerLine"></v-divider>
            <div class="ml-5 mt-3 mb-3">
                <div class="mt-2"><span class="listTitle">Order No.</span>  <br><span class="listContent">{{orderItem.orderSeriesNo}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Order Type.</span>  <br><span class="capitaliseText">{{orderItem.orderType}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Order Date</span>  <br><span class="listContent">{{moment(orderItem.orderDate).format("dddd, MMMM Do YYYY, h:mm a")}}</span>  <br></div>
                <div class="mt-2"><span class="listTitle">Order Source</span>  <br><span class="capitaliseText">{{orderItem.orderSource}}</span>  <br> </div>
                <div v-if="orderItem.orderType == 'regular' " class="mt-2"><span class="listTitle">Payment</span>  <br><v-chip small class="text-capitalize" :color="getPaymentColor(orderItem.orderPaymentDetails.status)">{{orderItem.orderPaymentDetails.status.replace('_',' ')}}</v-chip><br></div>
                <div class="mt-2"><span class="listTitle">Order Items</span>  <br><span class="listContent">{{orderItem.cartItemsList}}</span> <br> </div>
                <div class="mt-2"><span class="listTitle">Items Count</span>  <br><span class="listContent">{{orderItem.cartItemsCount}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Bill Amount</span>  <br><span class="listContent">{{orderItem.orderBillAmount}}</span> <br> </div>
                <div class="mt-2"><span class="listTitle">Order Note</span>  <br><span class="listContent">{{orderItem.orderNote}}</span> <br> </div>
            </div>
            <v-divider class="ml-5 mr-5 dividerLine"></v-divider>
            <div>
                <v-row no-gutters>
                  <v-col v-if="orderItem.orderStatus == 'active' && orderItem.orderPaymentDetails.status == 'not_paid'" cols="6">
                      <div class="mt-4 ml-4 mb-4">
                          <v-btn @click="toViewCustomer(orderItem.customerId)" outlined class="editBtn" block normal dark elevation="0">
                              Edit Order
                          </v-btn>
                      </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col v-if="orderItem.orderStatus != 'cancelled'" cols="6">
                      <div class="mt-4 ml-4 mr-4 mb-4">
                          <v-btn @click="updateStatus('cancelled')" outlined class="cancelBtn" block normal dark elevation="0">
                              Cancel Order
                          </v-btn>
                      </div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                <v-col v-if="orderItem.orderType == 'regular'" cols="12">
                    <div class="mt-4 ml-4 mr-4 mb-4">
                        <v-btn outlined @click="activePaymentsDialog(orderItem.orderId)" class="editBtn" block normal dark elevation="0">
                            Edit Payment
                        </v-btn>
                    </div>
                </v-col>
                </v-row>
            </div>
        </v-list>
    </v-card>
    </div>
</template>
<script>
import moment from 'moment'
import router from '@/router'
  export default {
    name: 'BasketItem',
    props: {
     orderItem: Object
    },
    data: () => ({
        moment: moment
    }),
    methods: {
        toViewCustomer (customerId) {
            router.push({name: "viewCustomer", params: { customerId: customerId }})
            // this.$router.push({ name: "view-branches", params: { previousRoute: 'branches' } })
        },
        updateStatus (status) {
            const updateOrder = {
                status: status,
                orderId: this.orderItem.orderId
            }
            this.$emit('update-status', updateOrder)
        },
        getPaymentColor(paymentStatus) {
            if (paymentStatus == 'paid') {
                return "#4B9C6D"
            } else if (paymentStatus == 'Partially Paid') {
                return "#F2BA55"
            } else if (paymentStatus == 'not_paid') {
                return "#E14B4B"
            }
        },
        activePaymentsDialog ( id ) {
            router.push('edit-payment/'+id+'?page=basket')
        },
    }
  }
</script>
<style scoped>
.dividerLine{
color: rgba(255, 255, 255, 0.5);
}
.capitaliseText {
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
text-transform: capitalize;
}
.customerName{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.listTitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}
.listContent {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}

.houseNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 24px;
color: #FFFFFF;
}

.houseText {
font-family: Montserrat;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #FFFFFF;
}

.addToBasketBtn {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #FFFFFF;
}
.cancelBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #E14B4B;
}
.editBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #F2BA55;
}
.paymentBtn {
text-transform: unset !important;
background: #F2BA55;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
color: #ffffff;
}
.text-capitalize {
    text-transform: capitalize !important;
}
</style>
